<template>
  <v-card :flat="forprint">
    <v-card-text>
      <v-container v-if="!isLoading">
        <v-row dense class="mb-12">
          <v-col cols="6">
            {{now}}
          </v-col>
          <v-col class="text-right" cols="6" >
            Registration Summary
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="headline">
            {{tournament.organization.name}}
          </v-col>
          <v-col cols="12" class="title">
            {{tournament.name}}
          </v-col>
          <v-col cols="12" class="subheading">
            {{dateDisplay}} - {{tournament.locationNames | formatArraySorted}}
          </v-col>
          <v-col cols="12" class="pt-4 pb-0">
            <b>Status: {{regDates.open ? 'Open' : 'Closed'}}</b>
          </v-col>
          <v-col cols="12" class="py-0">
            Registration Opened: {{regDates.start}}
          </v-col>
          <v-col cols="12" class="pt-0">
            Registration {{regDates.open ? 'Closes' : 'Closed'}}: {{regDates.end}}
          </v-col>
        </v-row>
        <v-simple-table
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Division</th>
                <th class="text-center">Registrations</th>
                <th class="text-left">Withdrawals</th>
                <th class="text-left">Active</th>
              </tr>
            </thead>
            <tr
              v-for="item in dataRows"
              :key="item.name"
            >
              <td>{{item.name}}</td>
              <td class="text-center">{{item.teams}}</td>
              <td class="text-center">{{item.dropped}}</td>
              <td class="text-center">{{item.active}}</td>
            </tr>
            <tr class="total">
              <td>{{totalRow.name}}</td>
              <td class="text-center">{{totalRow.teams}}</td>
              <td class="text-center">{{totalRow.dropped}}</td>
              <td class="text-center">{{totalRow.active}}</td>
            </tr>
          </template>
        </v-simple-table>
        <v-row dense class="not-printable mt-3">
          <v-col class="text-center" cols="12" >
            <v-btn color="color3 color3Text--text" small fab @click.stop="print">
              <v-icon>fas fa-print</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <div class="foot">
      {{url}}
    </div>
    <div class="footer">
      © {{ new Date().getFullYear() }} VolleyballLife.com. All rights reserved.<br>
      Powered by Mohawk Man Technologies LLC
    </div>
  </v-card>

</template>

<script>
import sumBy from 'lodash.sumby'
import moment from 'moment'

export default {
  props: ['tournament', 'forprint', 'load', 'registrationData'],
  data () {
    return {
      loading: false,
      registrations: []
    }
  },
  computed: {
    isLoading () {
      return this.loading || this.tournament.divisions.filter(f => !f.hydrated).length
    },
    dateDisplay () {
      return this.$options.filters.readableDateRange(this.tournament.startDate, this.tournament.endDate)
    },
    headers () {
      return [
        {
          text: 'Division',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Registrations',
          align: 'center',
          sortable: true,
          value: 'teams'
        },
        {
          text: 'Withdrawals',
          align: 'center',
          sortable: true,
          value: 'dropped'
        },
        {
          text: 'Active',
          align: 'center',
          sortable: true,
          value: 'active'
        }
      ]
    },
    dataRows () {
      const data = this.registrationData
      if (!data) return

      const divisions = this.tournament.publicDivisions
      const results = divisions.map(m => {
        return {
          name: m.name,
          gender: m.gender.name,
          division: m.division.name,
          teams: data.filter(f => f.divisionId === m.id).length,
          dropped: data.filter(f => f.divisionId === m.id && f.isDeleted).length,
          active: data.filter(f => f.divisionId === m.id && !f.isDeleted).length,
          total: false
        }
      })
      return results
    },
    totalRow () {
      return {
        id: 0,
        name: 'Total',
        teams: sumBy(this.dataRows, 'teams'),
        dropped: sumBy(this.dataRows, 'dropped'),
        active: sumBy(this.dataRows, 'active'),
        total: true
      }
    },
    now () {
      return moment().format('llll')
    },
    url () {
      return window.location.href
    },
    regDates () {
      if (this.isLoading) return { start: null, end: null }
      const starts = this.tournament.divisions.map(m => {
        return m.registrationWindows[0].dtStart
      })
      const ends = this.tournament.divisions.map(m => {
        return m.registrationWindows[0].dtEnd
      })
      return {
        start: moment.min(starts).format('MM/DD/YYYY h:mma'),
        end: moment.max(ends).format('MM/DD/YYYY h:mma'),
        open: moment.max(ends).isAfter()
      }
    }
  },
  methods: {
    print () {
      window.print()
    }
  }
}
</script>

<style scoped>
.headline {
  border-bottom: 3px solid #000;
}
.total td {
  border-top: 2px solid #000 !important;
  font-weight: 800;
}
@media print {
  .foot {
    position: fixed;
    bottom: 3px;
    left: 3px;
  }
  .footer {
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
}
@media screen {
  .foot,
  .footer {
    display: none;
  }
}
</style>
